<template>
  <div class="row">
    <div class="col-md-12">
      <div class="form-section">
        <b-form v-on:submit.prevent="onSubmit">
          <b-form-group
            id="input-group-MONTHLY_NO_ACTIVATION_MSG"
            label="Monthly No Record Message"
            label-for="input-MONTHLY_NO_ACTIVATION_MSG"
          >
            <b-form-input
              id="input-MONTHLY_NO_ACTIVATION_MSG"
              v-model="item.MONTHLY_NO_ACTIVATION_MSG"
              type="text"
              required
              placeholder="Enter Monthly No Record Message"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-DAILY_NOTIFICATION_TITLE_MSG"
            label="Daily Notification Title"
            label-for="input-DAILY_NOTIFICATION_TITLE_MSG"
          >
            <b-form-input
              id="input-DAILY_NOTIFICATION_TITLE_MSG"
              v-model="item.DAILY_NOTIFICATION_TITLE_MSG"
              type="text"
              required
              placeholder="Enter Daily Notification Title"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            id="input-group-DAILY_NOTIFICATION_BODY_MSG"
            label="Daily Notification Description"
            label-for="input-DAILY_NOTIFICATION_BODY_MSG"
          >
            <b-form-input
              id="input-DAILY_NOTIFICATION_BODY_MSG"
              v-model="item.DAILY_NOTIFICATION_BODY_MSG"
              type="text"
              required
              placeholder="Enter Daily Notification Description"
            ></b-form-input>
          </b-form-group>

          <div class="form-submit">
            <b-button type="submit" variant="secondary">
              <b-spinner small v-if="isLoading"></b-spinner>Submit
            </b-button>
          </div>
        </b-form>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.form-section {
  background-color: white;
  padding: 10px;
  margin-top: 10px;
}
.btn-info {
  background-color: #b5b5c3;
  border-color: #b5b5c3;
}
</style>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";

export default {
  data() {
    return {
      isLoading: false,
      item: {}
    };
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault();
      return new Promise(resolve => {
        this.isLoading = true;
        ApiService.post("config", this.item)
          .then(({ data }) => {
            this.isLoading = false;
            if (data && data.response && data.response.status) {
              this.$bvToast.toast("Settings has been updated sucessfully!", {
                title: "Success",
                variant: "success"
              });
            } else {
              this.$bvToast.toast(
                "Something went wrong while saving settings...",
                {
                  title: "Error",
                  variant: "danger"
                }
              );
            }
            resolve();
          })
          .catch(() => {
            this.isLoading = false;
            this.$bvToast.toast(
              "Something went wrong while saving settings...",
              {
                title: "Error",
                variant: "danger"
              }
            );
          });
      });
    },
    getItem: function() {
      return new Promise(resolve => {
        ApiService.get("config")
          .then(({ data }) => {
            this.item =
              data.response && data.response.settings
                ? data.response.settings
                : null;

            resolve();
          })
          .catch(() => {
            this.$bvToast.toast(
              "Something went wrong while retrieving data from external server...",
              {
                title: "Error",
                variant: "danger"
              }
            );
          });
      });
    }
  },
  mounted() {
    this.getItem();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Settings", route: "settings" }
    ]);
  },
  computed: {}
};
</script>
